import { useEffect, useState } from "react"

import { TickerTape } from "react-ts-tradingview-widgets"
import { removeElementsByClassName } from "@/lib/util"

export function SAMTickerTape() {
  const [removed, setRemoved] = useState(false)

  useEffect(() => {
    if (!removed) {
      setInterval(() => {
        removeElementsByClassName("tradingview-widget-copyright")
        setRemoved(true)
      }, 300)
    }
  })

  return (
    <div className="tv-ticker-tape-wrapper">
      <TickerTape
        colorTheme="dark"
        isTransparent={true}
        displayMode="compact"
      />
    </div>
  )
}
