import { useEffect, useState } from "react"

import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets"
import { Box } from "@mui/material"

export default function SAMAdvancedRealTimeChart() {
  const [showChart, setShowChart] = useState(false)

  useEffect(() => {
    setInterval(() => {
      setShowChart(true)
    }, 200)
  }, [])

  function renderChart() {
    return (
      <AdvancedRealTimeChart
        theme="dark"
        symbol="BTCUSD"
        hide_side_toolbar={true}
        width="100%"
        height={665}
        style="0"
      />
    )
  }

  return (
    <Box
      component="div"
      className="tv-adv-realtime-chart-wrapper"
      sx={{ m: -3 }}>
      {showChart && renderChart()}
    </Box>
  )
}
